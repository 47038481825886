import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import MobileHeader from './components/MobileHeader';
function About() {
  
  return (
   <div className='main-holder'>
    <MobileHeader />
    <div className="main1">
    <Header />
    <div className='main-side'>
     <div className='main-text-center1'>
       <p className='main-text-header'>About Me</p>
       <p className='main-text-additional-details1'>

       I am a sophomore at Tufts University, double majoring in Computer Engineering and Applied Physics. With four years of software development experience, I’ve gained expertise through professional roles, freelance projects, and personal work. My skills span web, app, and AI technologies. Most recently, I interned as an app developer at Modo Labs, where I leveraged AWS to enhance university applications. As a passionate engineer, I am always eager to learn and tackle new challenges.
       </p>
       </div>
    </div>
    <div className='main-side'>
    
 <img src={require('./suitnobg.png')} className='profilephotosuit'/>
    </div>
   
    
   
   </div>
   <div className="main2">

    <div className='main2-side'>
     <div className='main-text-center2'>
       <p className='main-text-header'>About Me</p>
       <p className='main-text-additional-details1'>
       I am a sophomore at Tufts University, double majoring in Computer Engineering and Applied Physics. With five years of software development experience, I’ve gained expertise through professional roles, freelance projects, and personal work. My skills span web, app, and AI technologies. Most recently, I interned as an app developer at Modo Labs, where I leveraged AWS to enhance university applications. As a passionate engineer, I am always eager to learn and tackle new challenges.


       </p>
       </div>
    </div>
    <div className='main2-side'>
    
 <img src={require('./suitnobg.png')} className='profilephotosuit'/>
    </div>
   
    
   
   </div>
   <Footer />
   </div>
  );
}

export default About;
